export default [
  {
    path: '/app/offers',
    name: 'Offers',
    component: () => import('@/views/Admin/Offers/Index.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/offers/open',
    name: 'OpenOffers',
    component: () => import('@/views/Admin/Offers/OpenIndex.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Açık Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/offers/requests',
    name: 'OfferRequests',
    component: () => import('@/views/Admin/Offers/Requests/Index.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Teklif Talepleri',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/app/offers/request/view/:id',
    name: 'OfferRequestView',
    component: () => import('@/views/Admin/Offers/Requests/View.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Teklif Talepleri',
          to: '/app/offers/requests',
          active: false,
        },
        {
          text: 'Detay',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_REQUEST_VIEW',
      action: 'read',
    },
  },
  {
    path: '/app/offers/customer-search',
    name: 'OfferCustomerSearch',
    component: () => import('@/views/Admin/Offers/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/add-project/:id_customers',
    name: 'OfferAdd',
    component: () => import('@/views/Admin/Offers/AddProjects.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/add/:id_projects',
    name: 'OfferAdd',
    component: () => import('@/views/Admin/Offers/Add.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/request-add/:id_baskets',
    name: 'OfferRequestAdd',
    component: () => import('@/views/Admin/Offers/RequestAdd.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/edit/:id',
    name: 'OfferEdit',
    component: () => import('@/views/Admin/Offers/Edit.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/offers/revised/:id_offers',
    name: 'OfferEdit',
    component: () => import('@/views/Admin/Offers/Revised.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Revize Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/offers/view/:id',
    name: 'OfferView',
    component: () => import('@/views/Admin/Offers/View.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_VIEW',
      action: 'read',
    },
  },
]
