import axiosIns from '@/libs/axios'
import moment from 'moment'

const ROOT_URL = '/Admin/data/projects'
export default {
  namespaced: true,
  state: {
    customerList: [],
    dataList: [],
    costData: [],
    dataCounts: 0.00,
    dataItem: {
      id: null,
      pnumber: null,
      title: null,
      notes: null,
      idate: moment().format('YYYY-MM-DD'),
      pdate: moment().format('YYYY-MM-DD'),
      x_area: null,
      y_area: null,
      z_area: null,
      x_door: null,
      y_door: null,
      z_door: null,
      m2: null,
      id_customers: null,
      id_project_statuses: null,
      id_projects: null,
      id_confirm_users: null,
      id_users: null,
      id_currencies: null,
      projectPrice: 0,
      logs: [],
      submitData: false,
    },
    search: {
      keyword: null,
      id_users: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    costSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    customerList(state) {
      return state.customerList
    },
    dataList(state) {
      return state.dataList
    },
    costData(state) {
      return state.costData
    },
    search(state) {
      return state.search
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    costSaveStatus(state) {
      return state.costSave
    },
  },
  mutations: {
    SET_CUSTOMER_LIST(state, data) {
      state.customerList = data
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_COST_DATA(state, data) {
      state.costData = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_COST_SAVE(state, data) {
      state.costSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        pnumber: null,
        title: null,
        notes: null,
        idate: moment().format('YYYY-MM-DD'),
        pdate: moment().format('YYYY-MM-DD'),
        x_area: null,
        y_area: null,
        z_area: null,
        x_door: null,
        y_door: null,
        z_door: null,
        m2: null,
        id_customers: null,
        id_project_statuses: null,
        id_projects: null,
        id_confirm_users: null,
        id_users: null,
        logs: [],
        submitData: null,
      }
    },
  },
  actions: {
    customerSearch({ commit }, keyword) {
      axiosIns
        .post(`${ROOT_URL}/customerSearch`, { keyword })
        .then(response => {
          commit('SET_CUSTOMER_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },

    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'projects.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getCosts({ commit }, id) {
      const data = JSON.stringify({
        id_projects: id,
      })
      return axiosIns
        .post(`${ROOT_URL}/getCosts`, data)
        .then(response => {
          commit('SET_COST_DATA', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItemPricing({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'projects.id': id,
        },
        array: false,
        pricingList: true,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      delete data.logs // log gonderilmesine gerek yok
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveCosts({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveCosts`, data)
        .then(response => {
          commit('SET_COST_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveCurrencies({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveCurrencies`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
