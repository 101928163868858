import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/offers'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      onumber: null,
      title: null,
      notes: null,
      discount_amount: null,
      discount_rate: null,
      content: null,
      terms: null,
      odate: null,
      vdate: null,
      id_customers: null,
      id_projects: null,
      id_company: null,
      id_offer_statuses: null,
      id_customer_related_person: null,
      id_offers: null,
      id_confirm_users: null,
      id_users: null,
      confirmed: null,
      currency: null,
      id_currencies: null,
      denied: null,
      offer_lines: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLineSave: {
      message: null,
      status: null,
      id: null,
    },
    search: {
      keyword: null,
      id_users: null,
    },
    customerSend: {
      to: [],
      message: null,
      modalStatus: false,
      sending: false,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLineSaveStatus(state) {
      return state.dataLineSave
    },
    search(state) {
      return state.search
    },
    customerSend(state) {
      return state.customerSend
    },
    customerSendResult(state) {
      return state.customerSendResult
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LINE_SAVE(state, data) {
      state.dataLineSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        onumber: null,
        title: null,
        notes: null,
        discount_amount: null,
        discount_rate: null,
        content: null,
        terms: null,
        odate: null,
        vdate: null,
        id_customers: null,
        id_projects: null,
        id_offer_statuses: null,
        id_customer_related_person: null,
        id_company: null,
        id_offers: null,
        id_confirm_users: null,
        id_users: null,
        confirmed: null,
        denied: null,
        currency: null,
        id_currencies: null,
        offer_lines: [],
      }
    },

  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    setContent({ commit }, params) {
      const data = JSON.stringify({
        id_customers: params,
      })
      return axiosIns
        .post(`${ROOT_URL}/createContent`, data)
        .then(response => response.data.data)
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'offers.id': params.id,
        },
        array: false,
        isUpdate: params.isUpdate,
        isRevised: params.isRevised,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataView({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/getOffer`, params)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    offerAppend({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/offerAppend`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },

    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeLine({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteLine`, { id })
        .then(response => {
          commit('SET_DATA_LINE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    customerSend({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/customerSend`, params)
        .then(response => {
          commit('SET_SEND_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
