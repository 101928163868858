export default [
  {
    path: '/admin/defines/project-document-types',
    name: 'ProjectDocTypes',
    component: () => import('@/views/Admin/Defines/Project_document_types/Index.vue'),
    meta: {
      pageTitle: 'Proje Doküman Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/project-document-types/add',
    name: 'ProjectDocTypeAdd',
    component: () => import('@/views/Admin/Defines/Project_document_types/Add.vue'),
    meta: {
      pageTitle: 'Proje Doküman Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/project-document-types',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/project-document-types/edit/:id',
    name: 'ProjectDocTypeEdit',
    component: () => import('@/views/Admin/Defines/Project_document_types/Edit.vue'),
    meta: {
      pageTitle: 'Proje Doküman Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/project-document-types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
