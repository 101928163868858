import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/offer_lines'
export default {
  namespaced: true,
  state: {
    dataList: [],
    productList: [],
    dataCounts: 0,
    dataItem:
      {
        id: null,
        content: null,
        listed_price: null,
        id_products: null,
        id_projects: null,
        id_currencies: null,
        id_units: null,
        id_taxes: null,
        id_offers: null,
        id_users: null,
        submitData: false,
      },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    productSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    productList(state) {
      return state.productList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_PRODUCT_LIST(state, data) {
      state.productList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_PRODUCT_SAVE(state, data) {
      state.productSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        content: null,
        listed_price: null,
        id_products: null,
        id_projects: null,
        id_currencies: null,
        id_units: null,
        id_taxes: null,
        id_offers: null,
        id_users: null,
        submitData: false,
      }
    },
    RESET_PRODUCT_DATA(state) {
      state.productList = []
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'offer_lines.id': id,
        },
        array: false,
      })
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getProductList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/productData`, data)
        .then(response => {
          commit('SET_PRODUCT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getNewProductList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/addProductRow`, data)
        .then(response => {
          commit('SET_PRODUCT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    batchInsert({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/batchInsert`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
