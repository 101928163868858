import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/customers'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      cnumber: null,
      company: null,
      name: null,
      phone: null,
      phone_region_code: 90,
      phone_region_iso2: 'TR',
      phone2: null,
      phone2_region_code: 90,
      phone2_region_iso2: 'TR',
      fax: null,
      fax_region_code: 90,
      fax_region_iso2: 'TR',
      email: null,
      notes: null,
      tax_office: null,
      tax_number: null,
      address: null,
      id_sectors: null,
      id_meets: null,
      id_countries: null,
      id_cities: null,
      related_persons: [
        {
          id: null,
          name: null,
          email: null,
          phone: null,
          phone_region_code: 90,
          phone_region_iso2: 'TR',
          id_related_person_types: null,
        },
      ],
      activities: [],
      sectors: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    phoneControl: {
      status: false,
      id: null,
    },
    emailControl: {
      status: false,
      id: null,
    },
    filterData: {
      id_sectors: [],
      id_activities: [],
      id_meets: null,
      id_countries: null,
      id_cities: null,
      id_users: null,
    },
    search: {
      keyword: null,
    },
    detailForm: false,
    detailSearch: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    phoneControl(state) {
      return state.phoneControl
    },
    emailControl(state) {
      return state.emailControl
    },
    detailForm(state) {
      return state.detailForm
    },
    detailSearch(state) {
      return state.detailSearch
    },
    filterData(state) {
      return state.filterData
    },
    search(state) {
      return state.search
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_PHONE_CONTROL(state, data) {
      state.phoneControl = data
    },
    SET_EMAIL_CONTROL(state, data) {
      state.emailControl = data
    },
    SET_DETAIL_FORM(state, data) {
      state.detailForm = data
    },
    SET_DETAIL_SEARCH(state, data) {
      state.detailSearch = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        cnumber: null,
        company: null,
        name: null,
        phone: null,
        phone_region_code: 90,
        phone_region_iso2: 'TR',
        phone2: null,
        phone2_region_code: 90,
        phone2_region_iso2: 'TR',
        fax: null,
        fax_region_code: 90,
        fax_region_iso2: 'TR',
        email: null,
        notes: null,
        tax_office: null,
        tax_number: null,
        address: null,
        id_sectors: null,
        id_meets: null,
        id_countries: null,
        id_cities: null,
        related_persons: [
          {
            id: null,
            name: null,
            email: null,
            phone: null,
            phone_region_code: 90,
            phone_region_iso2: 'TR',
            id_related_person_types: null,
          },
        ],
        activities: [],
        sectors: [],
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'customers.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    /* Custom */
    getPhoneControl({ commit }, params) {
      axiosIns
        .post(`${ROOT_URL}/phoneControl`, params)
        .then(response => {
          commit('SET_PHONE_CONTROL', response.data)
        })
        .catch(error => console.error(error))
    },
    getEmailControl({ commit }, params) {
      axiosIns
        .post(`${ROOT_URL}/emailControl`, params)
        .then(response => {
          commit('SET_EMAIL_CONTROL', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
