export default [
  {
    path: '/admin/defines/supplier-sectors',
    name: 'SupplierSectors',
    component: () => import('@/views/Admin/Defines/Supplier_sectors/Index.vue'),
    meta: {
      pageTitle: 'Tedarikçi Sektörleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/supplier-sectors/add',
    name: 'SupplierSectorAdd',
    component: () => import('@/views/Admin/Defines/Supplier_sectors/Add.vue'),
    meta: {
      pageTitle: 'Tedarikçi Sektörleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/supplier-sectors',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/supplier-sectors/edit/:id',
    name: 'SupplierSectorEdit',
    component: () => import('@/views/Admin/Defines/Supplier_sectors/Edit.vue'),
    meta: {
      pageTitle: 'Tedarikçi Sektörleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/supplier-sectors',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
