export default [
  {
    path: '/app/reports/interviews',
    name: 'ReportInterviews',
    component: () => import('@/views/Admin/Reports/Interviews/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Görüşmeler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_INTERVIEW',
      action: 'read',
    },
  },
  {
    path: '/app/reports/interview-report',
    name: 'ReportInterviewExcel',
    component: () => import('@/views/Admin/Reports/Interviews/InterviewReport.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Görüşme Dökümü',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_INTERVIEW_EXCEL',
      action: 'read',
    },
  },
  {
    path: '/app/reports/offers',
    name: 'ReportOffers',
    component: () => import('@/views/Admin/Reports/Offers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_OFFER',
      action: 'read',
    },
  },
  {
    path: '/app/reports/customers',
    name: 'ReportCustomers',
    component: () => import('@/views/Admin/Reports/Customers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteri Kartı Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_CUSTOMERS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/customers/detail/:id',
    name: 'ReportCustomerDetail',
    component: () => import('@/views/Admin/Reports/Customers/Detail.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteri Kartı Arama',
          to: '/app/reports/customers',
          active: false,
        },
        {
          text: 'Müşteri Detayı',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_CUSTOMERS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/customers/export',
    name: 'ReportCustomerExport',
    component: () => import('@/views/Admin/Reports/Customers/customerReport.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteri Listesi',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_CUSTOMER_LIST',
      action: 'read',
    },
  },
]
