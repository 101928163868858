export default [
  {
    path: '/app/warehouses',
    name: 'Warehouses',
    component: () => import('@/views/Admin/Warehouses/Index.vue'),
    meta: {
      pageTitle: 'Depo Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_WAREHOUSES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/Warehouses/edit/:id',
    name: 'WarehouseEdit',
    component: () => import('@/views/Admin/Warehouses/Edit.vue'),
    meta: {
      pageTitle: 'Depo Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/warehouses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_WAREHOUSES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/warehouses/add',
    name: 'WarehouseAdd',
    component: () => import('@/views/Admin/Warehouses/Add.vue'),
    meta: {
      pageTitle: 'Depo Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/warehouses',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_WAREHOUSES_ADD',
      action: 'read',
    },
  },
]
