export default [
  {
    path: '/app/contracts',
    name: 'Contracts',
    component: () => import('@/views/Admin/Contracts/Index.vue'),
    meta: {
      pageTitle: 'Sözleşmeler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTRACTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/contracts/customer-search',
    name: 'ContractSearch',
    component: () => import('@/views/Admin/Contracts/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Sözleşmeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contracts',
          active: false,
        },
        {
          text: 'Sözleşme Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTRACTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/contracts/add/:id',
    name: 'ContractsAdd',
    component: () => import('@/views/Admin/Contracts/Add.vue'),
    meta: {
      pageTitle: 'Sözleşmeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contracts',
          active: false,
        },
        {
          text: 'Sözleşme Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTRACTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/contracts/edit/:id',
    name: 'ContractsAdd',
    component: () => import('@/views/Admin/Contracts/Edit.vue'),
    meta: {
      pageTitle: 'Sözleşmeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contracts',
          active: false,
        },
        {
          text: 'Sözleşme Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTRACTS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/contracts/view/:id',
    name: 'ContractView',
    component: () => import('@/views/Admin/Contracts/View.vue'),
    meta: {
      pageTitle: 'Sözleşmeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/contracts',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_CONTRACTS_VIEW',
      action: 'read',
    },
  },
]
