import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/reports/customers'
export default {
  namespaced: true,
  state: {
    interviews: [],
    offers: [],
    projects: [],
    ProjectRequests: [],
  },
  getters: {
    interviews(state) {
      return state.interviews
    },
    offers(state) {
      return state.offers
    },
    projects(state) {
      return state.projects
    },
    projectRequests(state) {
      return state.ProjectRequests
    },
  },
  mutations: {
    SET_INTERVIEWS_DATA(state, data) {
      state.interviews = data
    },
    SET_OFFERS_DATA(state, data) {
      state.offers = data
    },
    SET_CATALOGS_DATA(state, data) {
      state.projects = data
    },
    SET_OFFER_REQUESTS_DATA(state, data) {
      state.ProjectRequests = data
    },
  },
  actions: {
    getInterviews({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/interviews`, { id_customers: id })
        .then(response => {
          commit('SET_INTERVIEWS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getOffers({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/offers`, { id_customers: id })
        .then(response => {
          commit('SET_OFFERS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getCatalogs({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/projects`, { id_customers: id })
        .then(response => {
          commit('SET_CATALOGS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getProjectRequests({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/projectRequests`, { id_customers: id })
        .then(response => {
          commit('SET_OFFER_REQUESTS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
