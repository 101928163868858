export default [
  {
    path: '/app/stocks',
    name: 'Stocks',
    component: () => import('@/views/Admin/Stocks/Index.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/list',
    name: 'StockLists',
    component: () => import('@/views/Admin/Stocks/List.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/add',
    name: 'StocksAdd',
    component: () => import('@/views/Admin/Stocks/Add.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/quickadd',
    name: 'QuickStocksAdd',
    component: () => import('@/views/Admin/Stocks/QuickAdd.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Toplu Giriş',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/quickout',
    name: 'QuickStocksOut',
    component: () => import('@/views/Admin/Stocks/QuickOut.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Toplu Çıkış',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/projectOut',
    name: 'QuickStocksProject',
    component: () => import('@/views/Admin/Stocks/AddProjects.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Proje İmalat Stok Çıkışı',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/projectOut/:id_projects',
    name: 'QuickStocksProjectOut',
    component: () => import('@/views/Admin/Stocks/ProjectOut.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Proje İmalat Stok Çıkışı',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/edit/:id',
    name: 'StocksEdit',
    component: () => import('@/views/Admin/Stocks/Edit.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/view/:id',
    name: 'StocksEdit',
    component: () => import('@/views/Admin/Stocks/View.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/stocks',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/stocks/addlines/:id',
    name: 'StocksAddLines',
    component: () => import('@/views/Admin/Stocks/AddLines.vue'),
    meta: {
      pageTitle: 'Hareket Ekle',
      breadcrumb: [
        {
          text: 'Stok Listesi',
          to: '/app/stocks/',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_STOCKS_ADD',
      action: 'read',
    },
  },
]
