import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/project_lines'
export default {
  namespaced: true,
  state: {
    dataList: [],
    productData: [],
    dataCounts: 0,
    dataItem: [
      {
        id: null,
        amount: null, // Added amount field
        notes: null, // Added notes field
        price: null, // Added notes field
        id_projects: null,
        id_products: null,
        id_product_groups: null,
        id_colors: null,
        id_currencies: null,
        id_units: null,
        id_users: null,
        submitData: false,
      },
    ],
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    priceSave: {
      message: null,
      status: null,
      id: null,
    },
    productSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    productList(state) {
      return state.productData
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    priceSaveStatus(state) {
      return state.priceSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_PRODUCT_LIST(state, data) {
      state.productData = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_PRICE_SAVE(state, data) {
      state.priceSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        amount: null,
        notes: null,
        price: null,
        id_projects: null,
        id_products: null,
        id_product_groups: null,
        id_colors: null,
        id_currencies: null,
        id_units: null,
        id_users: null,
        submitData: false,
      }
    },
    RESET_PRODUCT_DATA(state) {
      state.productData = []
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getProductList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/projectLines`, data)
        .then(response => {
          commit('SET_PRODUCT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'project_lines.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    savePrice({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_PRICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
