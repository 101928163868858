export default [
  {
    path: '/app/projects',
    name: 'Projects',
    component: () => import('@/views/Admin/Projects/Index.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/projects/customer-search',
    name: 'ProjectSearch',
    component: () => import('@/views/Admin/Projects/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/projects/add/:id',
    name: 'ProjectAdd',
    component: () => import('@/views/Admin/Projects/Add.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/projects/add/:id/:id_interviews',
    name: 'ProjectAddInterview',
    component: () => import('@/views/Admin/Projects/Add.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/projects/revised/:id',
    name: 'ProjectAdd',
    component: () => import('@/views/Admin/Projects/Revised.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/projects/edit/:id',
    name: 'ProjectEdit',
    component: () => import('@/views/Admin/Projects/Edit.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/projects/view/:id',
    name: 'ProjectEdit',
    component: () => import('@/views/Admin/Projects/View.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_VIEW',
      action: 'read',
    },
  },
  {
    path: '/app/projects/addproduct/:id',
    name: 'ProjectProductAdd',
    component: () => import('@/views/Admin/Projects/AddProduct.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje',
          active: false,
        },
        {
          text: 'Ürün Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/projects/editproduct/:id',
    name: 'ProjectProductAdd',
    component: () => import('@/views/Admin/Projects/EditProduct.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/projects',
          active: false,
        },
        {
          text: 'Proje',
          active: false,
        },
        {
          text: 'Ürün Düzenle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/projects/requests',
    name: 'ProjectRequests',
    component: () => import('@/views/Admin/Projects/Requests/Index.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Proje Talepleri',
          to: '/app/projects/requests',
          active: false,
        },
      ],
      resource: 'ADMIN_PROJECT_LIST',
      action: 'read',
    },
  },
]
