export default [
  {
    path: '/app/proforma-invoices',
    name: 'Proforms',
    component: () => import('@/views/Admin/Proforma_invoices/Index.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/proforma-invoices/customer-search',
    name: 'ProformSearch',
    component: () => import('@/views/Admin/Proforma_invoices/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/proforma-invoices',
          active: false,
        },
        {
          text: 'Proforma Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/proforma-invoices/add-offer/:id_customers',
    name: 'ProformaOfferAdd',
    component: () => import('@/views/Admin/Proforma_invoices/AddOffers.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Tüm Proformalar',
          to: '/app/proforma-invoices',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/proforma-invoices/add/:id',
    name: 'ProformAdd',
    component: () => import('@/views/Admin/Proforma_invoices/Add.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/proforma-invoices',
          active: false,
        },
        {
          text: 'Proforma Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/proforma-invoices/edit/:id',
    name: 'ProformEdit',
    component: () => import('@/views/Admin/Proforma_invoices/Edit.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/proforma-invoices',
          active: false,
        },
        {
          text: 'Proforma Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/proforma-invoices/view/:id',
    name: 'ProformEdit',
    component: () => import('@/views/Admin/Proforma_invoices/View.vue'),
    meta: {
      pageTitle: 'Proformalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/proforma-invoices',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROFORMA_VIEW',
      action: 'read',
    },
  },
]
