import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/proforma_invoices'
export default {
  namespaced: true,
  state: {
    dataList: [],
    productList: [],
    offerList: [],
    offerCounts: 0,
    dataCounts: 0,
    dataItem: {
      id: null,
      pno: null,
      pdate: null,
      payment_type: null,
      notes: null,
      terms: null,
      discount_amount: null,
      discounted_grand_total: null,
      discount_rate: null,
      delivery_location: null,
      id_customer_related_person: null,
      delivery_type: null,
      origin: null,
      deadline: null,
      id_bank_accounts: null,
      id_customers: null,
      id_company: null,
      id_offers: null,
      proforma_lines: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLineSave: {
      message: null,
      status: null,
      id: null,
    },
    search: {
      keyword: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    offerList(state) {
      return state.offerList
    },
    productList(state) {
      return state.productList
    },
    offerCounts(state) {
      return state.offerCounts
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLineSaveStatus(state) {
      return state.dataLineSave
    },
    search(state) {
      return state.search
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_OFFER_LIST(state, data) {
      state.offerList = data
    },
    SET_PRODUCT_LIST(state, data) {
      state.productList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_OFFER_COUNTS(state, data) {
      state.offerCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LINE_SAVE(state, data) {
      state.dataLineSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        pno: null,
        pdate: null,
        payment_type: null,
        notes: null,
        terms: null,
        discount_amount: null,
        discounted_grand_total: null,
        discount_rate: null,
        delivery_location: null,
        id_customer_related_person: null,
        delivery_type: null,
        origin: null,
        deadline: null,
        id_bank_accounts: null,
        id_customers: null,
        id_company: null,
        id_offers: null,
        proforma_lines: [],
      }
    },
    RESET_PRODUCT_DATA(state) {
      state.productList = []
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getOfferList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/getOffers`, data)
        .then(response => {
          commit('SET_OFFER_LIST', response.data.data)
          commit('SET_OFFER_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getProductList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/productData`, data)
        .then(response => {
          commit('SET_PRODUCT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getNewProductList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/addProductRow`, data)
        .then(response => {
          commit('SET_PRODUCT_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'proforma_invoices.id': params.id,
        },
        array: false,
        isUpdate: params.isUpdate,
        isRevised: params.isRevised,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataView({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/getProforma`, params)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeLine({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteLine`, { id })
        .then(response => {
          commit('SET_DATA_LINE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
