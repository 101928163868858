export default [
  {
    path: '/admin/defines/supplier-activities',
    name: 'SupplierActivities',
    component: () => import('@/views/Admin/Defines/Supplier_activities/Index.vue'),
    meta: {
      pageTitle: 'Tedarikçi Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/supplier-activities/add',
    name: 'SupplierActivityAdd',
    component: () => import('@/views/Admin/Defines/Supplier_activities/Add.vue'),
    meta: {
      pageTitle: 'Tedarikçi Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/supplier-activities',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/supplier-activities/edit/:id',
    name: 'SupplierActivityEdit',
    component: () => import('@/views/Admin/Defines/Supplier_activities/Edit.vue'),
    meta: {
      pageTitle: 'Tedarikçi Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/supplier-activities',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
