export default [
  {
    path: '/admin/defines/contract-templates',
    name: 'ContractTemplates',
    component: () => import('@/views/Admin/Defines/Contract_templates/Index.vue'),
    meta: {
      pageTitle: 'Sözleşme Şablonları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/contract-templates/add',
    name: 'ContractTemplateAdd',
    component: () => import('@/views/Admin/Defines/Contract_templates/Add.vue'),
    meta: {
      pageTitle: 'Sözleşme Şablonları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/contract-templates',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/contract-templates/edit/:id',
    name: 'ContractTemplateEdit',
    component: () => import('@/views/Admin/Defines/Contract_templates/Edit.vue'),
    meta: {
      pageTitle: 'Sözleşme Şablonları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/contract-templates',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
