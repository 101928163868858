export default [
  {
    path: '/app/suppliers',
    name: 'Suppliers',
    component: () => import('@/views/Admin/Suppliers/Index.vue'),
    meta: {
      pageTitle: 'Tedarikçi Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPLIER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/suppliers/add',
    name: 'SupplierAdd',
    component: () => import('@/views/Admin/Suppliers/Add.vue'),
    meta: {
      pageTitle: 'Tedarikçi Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/suppliers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPLIER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/suppliers/edit/:id',
    name: 'SupplierEdit',
    component: () => import('@/views/Admin/Suppliers/Edit.vue'),
    meta: {
      pageTitle: 'Tedarikçi Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/suppliers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPLIER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/suppliers/view/:id',
    name: 'SupplierView',
    component: () => import('@/views/Admin/Suppliers/View.vue'),
    meta: {
      pageTitle: 'Tedarikçi Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/suppliers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPLIER_VIEW',
      action: 'read',
    },
  },
]
