export default [
  {
    path: '/admin/defines/project-statuses',
    name: 'ProjectStatuses',
    component: () => import('@/views/Admin/Defines/Project_statuses/Index.vue'),
    meta: {
      pageTitle: 'Proje Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/project-statuses/add',
    name: 'ProjectStatusAdd',
    component: () => import('@/views/Admin/Defines/Project_statuses/Add.vue'),
    meta: {
      pageTitle: 'Proje Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/project-statuses',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/project-statuses/edit/:id',
    name: 'ProjectStatusEdit',
    component: () => import('@/views/Admin/Defines/Project_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Proje Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/project-statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
