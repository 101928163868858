export default [
  {
    path: '/admin/defines/proforma-terms',
    name: 'ProformaTerms',
    component: () => import('@/views/Admin/Defines/Proforma_terms/Index.vue'),
    meta: {
      pageTitle: 'Proforma Özel Şartları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/proforma-terms/add',
    name: 'ProformaTermAdd',
    component: () => import('@/views/Admin/Defines/Proforma_terms/Add.vue'),
    meta: {
      pageTitle: 'Proforma Özel Şartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/proforma-terms',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/proforma-terms/edit/:id',
    name: 'ProformaTermEdit',
    component: () => import('@/views/Admin/Defines/Proforma_terms/Edit.vue'),
    meta: {
      pageTitle: 'Proforma Özel Şartları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/proforma-terms',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
